<template>
    <page-placeholder v-if="project.isLoaded">
        <v-row>
            <v-col :cols="12">
                <local-visibility-competitor/>
            </v-col>


            <v-col :cols="12">
                <local-visibility-keywords-positions/>
            </v-col>


            <v-col :cols="12">
                <local-visibility-my-business-keywords-positions/>
            </v-col>


            <v-col :cols="12">
                <local-visibility-local-advertising-presence/>
            </v-col>
        </v-row>
    </page-placeholder>
</template>


<script>
import PagePlaceholder from "@/components/yooda-components/PagePlaceholder";

import LocalVisibilityCompetitor from "@/components/yooda-components/app/localVisibility/LocalVisibilityCompetitor";
import LocalVisibilityKeywordsPositions from "@/components/yooda-components/app/localVisibility/LocalVisibilityKeywordsPositions";
import LocalVisibilityMyBusinessKeywordsPositions from "@/components/yooda-components/app/localVisibility/LocalVisibilityMyBusinessKeywordsPositions";
import LocalVisibilityLocalAdvertisingPresence from "@/components/yooda-components/app/localVisibility/LocalVisibilityLocalAdvertisingPresence";

export default {
    components: {
        PagePlaceholder,
        LocalVisibilityCompetitor,
        LocalVisibilityKeywordsPositions,
        LocalVisibilityMyBusinessKeywordsPositions,
        LocalVisibilityLocalAdvertisingPresence
    },
}

</script>
